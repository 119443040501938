import { Component } from "react"

import miiLogo from '../static/media/mii-logo.png'
import bmbfLogo from '../static/media/bmbf-logo.png'
import miracumLogo from '../static/media/miracum-logo.png'
import imiLogo from '../static/media/imi-logo.png'

class Footer extends Component {
    constructor(){
        super()
        this.state = {}
    }
    
    render() {
        return (
            <footer className="navbar-fixed-bottom navbar-default footer">
                    <a href="https://miracum.org" target="_blank" rel="noreferrer">
                        <img src={miracumLogo} alt="Miracum Logo" className='footerLogo' style={{width: '250px'}} />
                    </a>
                    <a href="https://www.medizininformatik-initiative.de/" target="_blank" rel="noreferrer">
                        <img src={miiLogo} alt="MII Logo" className='footerLogo' />
                    </a>
                    <a href="https://www.bmbf.de" target="_blank" rel="noreferrer">
                        <img src={bmbfLogo} alt="BMF Logo" className='footerLogo' />
                    </a>
                    <a href="https://imi-frankfurt.de" target="_blank" rel="noreferrer">
                        <img src={imiLogo} alt="MIG Logo" className='footerLogo' style={{width: '250px'}} />
                    </a>
            </footer>
        )
    }
}

export default Footer