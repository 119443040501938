import { Component } from "react"

import Welcome from './Welcome'
import Menu from "./Menu"
import StatusOverview from './StatusOverview'
import Footer from './Footer'
import RenderOnAnonymous from "./RenderOnAnonymous"
import RenderOnAuthenticated from "./RenderOnAuthenticated"

class App extends Component {
  constructor(){
    super()
    this.state = {}
  }
  
  render() {
    return (
        <div className="container">
          <RenderOnAnonymous>
            <Welcome />
          </RenderOnAnonymous>
          <RenderOnAuthenticated>
            <Menu />
            <StatusOverview />
          </RenderOnAuthenticated>
          <Footer />
        </div>
    )
  }
}

export default App
