import HeatMap from "react-heatmap-grid"
import { Component } from "react"

import httpService from '../services/HttpService'

class StatusOverview extends Component {
  constructor(){
    super()
    this.state = {
      miracumData: [],
      xLabels: ['Patienten', 'Fälle', 'Bewegungs-daten', 'Diagnosen', 'Prozeduren', 'Laborbefunde', 'Laborunter- suchungen', 'Medikamen-tenverabrei-chungen', 'Medikamen-tenverord-nungen', 'Broad Consents', 'Daten Empfangen', 'Letzte Lieferung Datamap'],
      queries: ['base/Patient?_profile=https://www.medizininformatik-initiative.de/fhir/core/modul-person/StructureDefinition/Patient&_summary=count',
                'base/Encounter?_profile=https://www.medizininformatik-initiative.de/fhir/core/modul-fall/StructureDefinition/KontaktGesundheitseinrichtung&type=einrichtungskontakt&_summary=count',
                'base/Encounter?_profile=https://www.medizininformatik-initiative.de/fhir/core/modul-fall/StructureDefinition/KontaktGesundheitseinrichtung&type=versorgungsstellenkontakt&_summary=count',
                'base/Condition?_profile=https://www.medizininformatik-initiative.de/fhir/core/modul-diagnose/StructureDefinition/Diagnose&_summary=count',
                'base/Procedure?_profile=https://www.medizininformatik-initiative.de/fhir/core/modul-prozedur/StructureDefinition/Procedure&_summary=count',
                'base/DiagnosticReport?_profile=https://www.medizininformatik-initiative.de/fhir/core/modul-labor/StructureDefinition/DiagnosticReportLab&_summary=count',
                'base/Observation?_profile=https://www.medizininformatik-initiative.de/fhir/core/modul-labor/StructureDefinition/ObservationLab&_summary=count',
                'base/MedicationAdministration?_profile=https://www.medizininformatik-initiative.de/fhir/core/modul-medikation/StructureDefinition/MedicationAdministration&_summary=count',
                'base/MedicationStatement?_profile=https://www.medizininformatik-initiative.de/fhir/core/modul-medikation/StructureDefinition/MedicationStatement&_summary=count',
                'base/Consent?_profile=http://fhir.de/ConsentManagement/StructureDefinition/Consent&_summary=count'],
      yLabels: [],
      siteData: []
    }
  }

  componentDidMount(){
    this.getData()
  }

  async getData() {
    const res = await httpService.getAxiosClient().get(process.env.REACT_APP_REPORT_URL)
    const { data } = await res;
    this.setState({ miracumData: data })
  }

  prepareData() {
    for(let site of this.state.miracumData) {
      this.state.yLabels.push(site.siteName.toUpperCase())
    }
    let tempQueryListData = []
    for(let queryLists of this.state.miracumData) {
      tempQueryListData.push(queryLists.datamapQueryList.queryList)
    }
    let index = 0
    for(let queryList of tempQueryListData) {
      let tempArray = []
      for(let query of queryList) {
        if(query.result) {
          tempArray.push(parseInt(query.result).toLocaleString('de'))
        }
        else {
          tempArray.push('-')
        }
      }
      tempArray.push(this.state.miracumData[index].receivedAt)
      tempArray.push(this.state.miracumData[index].datamapSubmittedAt)
      index++
      this.state.siteData.push(tempArray)
    }
  }

  render() {
    this.prepareData()
    return (
      <div className="heatmap">
        <HeatMap 
          xLabels={this.state.xLabels}
          xLabelWidth={200}
          height={75}
          yLabelWidth={70}
          yLabelTextAlign={"left"}
          background={"#2cb02a"} 
          yLabels={this.state.yLabels} 
          data={this.state.siteData}
          cellStyle={(background, value, min, max, data, x, y) => ({
            background: parseInt(value) === 0 ? '#BEFFB7' : parseInt(value) > 0 ? '#77DD77' : value === '-' ? '#ffd133' : '#b5d3e7',
            padding: '5px',
            fontSize: parseInt(value) === 0 ? '14px' : parseInt(value) > 0 ? '14px' : value === '-' ? '16px' : '12px'
          })}
          cellRender={(value) => value}
          title={(value, unit, index) => this.state.queries[index]}
        />
      </div>
    )
  }
}

export default StatusOverview
