import { Component } from "react"

import UserService from "../services/UserService"
import miracumLogo from'../static/media/miracum-logo.png'

class Menu extends Component {
  constructor(){
    super()
    this.state = {}
  }

  render() {
    return (
    <nav className="navbar navbar-default" style={{marginBottom: 70}}>
      <div className="container-fluid">
        <div className="navbar-header" style={{padding: '10px'}}>
        <a href="https://miracum.org" rel="noreferrer" target="_blank"><img src={ miracumLogo } alt="Miracum Logo" style={{ width: 250, float: 'left' }}/></a>
          <span className="navbarHeadline fontColor">STATUS OVERVIEW</span>
        </div>
        <div id="navbar">
          <button className="btn btn-warning navbar-btn navbar-right" style={{ marginRight: 0, marginTop: '3rem' }} onClick={() => UserService.doLogout()}>
            Logout
          </button>
          <p className="navbar-text navbar-right" style={{ marginRight: 15 , marginTop: '3.5rem', cursor: 'default' }}>
            Signed in as {UserService.getFirstName()}
          </p>
        </div>
      </div>
    </nav>
    )
  }
}

export default Menu
