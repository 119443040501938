import { Component } from "react"

import UserService from "../services/UserService"
import miracumLogo from '../static/media/miracum-logo.png'

class Welcome extends Component {
  constructor(){
    super()
    this.state = {}
  }

  render() {
    return (
      <div className="container">
        <div className="jumbotron">
          <a href="https://miracum.org"><img src={miracumLogo} alt="Miracum Logo" className='miracumLogo'/></a>
          <span className="headline fontColor">STATUS OVERVIEW</span>
            <button className="btn btn-lg btn-success signInBtn" onClick={() => UserService.doLogin()}>Please Sign In</button>
        </div>
      </div>
    )
  }
}

export default Welcome
